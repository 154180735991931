<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="listas" class="p-datatable-sm" v-model:selection="selected" dataKey="id_precio_lista" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['precio_lista']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} listas" :loading="loadingGrid">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Listas de Precios</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="precio_lista" header="Lista" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Lista</span>
							{{slotProps.data.precio_lista}}
						</template>
					</Column>
					<Column field="automatica" header="Automática" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Automática</span>
							<i v-if="slotProps.data.automatica == 'S'" class="pi pi-check"></i>														
						</template>
					</Column>
					<Column field="pct_descuento" header="% Descuento" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">% Descuento</span>
							{{formatPct(slotProps.data.pct_descuento)}}
						</template>
					</Column>
					<Column field="activa" header="Activa" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Activa</span>
							<i v-if="slotProps.data.activa == 'S'" class="pi pi-check"></i>							
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				
				<Dialog v-model:visible="nuevoDialog" :style="{width: '450px'}" header="Nueva Lista" :modal="true">
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="precio_lista">Nombre Lista</label>
							<InputText id="precio_lista" v-model="lista.precio_lista" required="true" autofocus :class="{'p-invalid': submitted && !lista.precio_lista}" />
							<small class="p-invalid" v-if="submitted && !lista.precio_lista">Este información es requerida</small>
						</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="nuevoDialog=false"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec" />
					</template>
				</Dialog>

				<Dialog v-model:visible="listaDialog" :style="{width: '850px'}" header="Lista de Precios" :modal="true" class="p-fluid">
					<!-- <img :src="'assets/demo/images/product/' + product.image" :alt="product.image" class="product-image" v-if="product.image" /> -->
					<div class="p-field">
						<label for="precio_lista">Nombre Lista</label>
						<InputText id="precio_lista" v-model="lista.precio_lista" required="true" autofocus @blur="actualizarLista" @change="lista.hayCambios=true" :class="{'p-invalid': submitted && !lista.precio_lista}" />
						<small class="p-invalid" v-if="submitted && !lista.precio_lista">Esta información es requerida</small>
					</div>
					<div class='p-d-flex p-jc-end'>
						<div class="p-field p-col-6 p-ai-center">
							<label class="p-mr-2 p-as-center" for="activa">Activa</label>
							<Checkbox class="p-m-1" v-model="lista.activaBool" :binary="true" @blur="actualizarLista" @change="lista.hayCambios=true"/>						
						</div>
					</div>						
					<!-- <div class="p-field">
						<label  class="p-m-1 p-ai-center"  for="pct_descuento">% Descuento</label>
						<InputNumber id="pct_descuento" v-model="lista.pct_descuento" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"/>					
					</div>	
					<div class="p-field p-col-6">
						<label class="p-m-1" for="automatica">Automática</label>
						<Checkbox class="p-m-1" v-model="lista.automaticaBool" :binary="true" />												
					</div> -->
					<Toolbar>
						<template #left>						
							<div class="p-inputgroup p-m-1">
								<label class="p-mr-1 p-as-center" for="producto">Producto</label>
								<InputText size="100" id="codigo" type="text" v-model="filtroProducto" v-on:keyup.enter="buscarProducto"/>				
								<Button icon="pi pi-search" @click="seleccionarProducto"/>							
							</div>
						</template>
					</Toolbar>									
					<div :style="{height: '425px'}">
					<DataTable ref="dt" :value="lista.detalle" class="p-datatable-sm" editMode="cell" @cellEditComplete="onCellEditComplete"  v-model:selection="selectedProductoPrecio" dataKey="id_precio_lista_det" 
					:paginator="false" 
					:scrollable="true"
					:loading="loadingGrid">
						<Column field="codigo" header="Código" headerStyle="width: 5%; text-align: left" bodyStyle="text-align: left">
						</Column>	
						<Column field="producto" header="Producto" headerStyle="width: 10%; text-align: left" bodyStyle="text-align: left">
						</Column>							
						<Column field="precio_unitario" header="Precio Unitario" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: right">
							<template #body="{data}">
								{{formatCurrency(data.precio_unitario)}}
							</template>
							<template #editor="slotProps">
								<InputNumber :modelValue="slotProps.data[slotProps.column.props.field]" @update:modelValue="onCellEdit($event, slotProps)" mode="decimal" :minFractionDigits="2"/>                    	
							</template>		
						</Column>	
						<Column>
							<template #body="slotProps">							
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="removerItem(slotProps.data)"/>
							</template>
						</Column>																											
					</DataTable>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
				
				<BuscarProducto ref="dlgBuscaProducto" :mostrar="productoDialog" tipo="" mostrar_conversiones="S" @cancelar="cerrarDlgProducto"  @seleccionar="elegirProducto" :filtro="filtroProducto" :key="productoDlgRefresh"/>
				
				<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">Esta seguro que desea eliminar la lista de precios? <br><b>[{{ lista.precio_lista }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="eliminar" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import InvService from '../service/inv.service';
import {FilterMatchMode} from 'primevue/api';
import BuscarProducto from '../components/BuscarProducto.vue';
// import Checkbox from 'primevue/checkbox';

export default {
	components:
	{
		Loading, BuscarProducto
	},
	data() {
		return {
			listas: null,
			listaDialog: false,
			productoDialog: false,
			filtroProducto: '',
			productoDlgRefresh: 0,	
            selProducto: {codigo: '', producto: '', unidad_medida: '', descripcion: ''},			
			selectedProductoPrecio: null,
			editingCellRows: {},
			nuevoDialog: false,			
			deleteDialog: false,
			lista: {
				hayCambios: false,
				detalle: []
			},
			selected: null,
			loading: false,
			loadingGrid: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	mounted() {
		this.loading = true;

		InvService.getListasPrecios(this.$store.state.auth.currentAppCtx).then(data => {
			this.listas = data;
			this.$store.state.auth.currentAppCtx.precio_listas = this.listas;
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
		);		
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
		formatPct(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
        isPositiveNumeric(val) {
			if(this.isNumeric(val)){
				let num = parseFloat(val);
				return num >= 0.00;
			} else {
				return false;
			}
		},	
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},			
		seleccionarProducto(){
			if(this.filtroProducto == '') {
				this.productoDialog = true;
				this.dlgBuscarProductoRefresh++;
			} else {
				this.buscarProducto();
			}
		},
		cerrarDlgProducto(){
			this.filtroProducto = '';
			this.productoDialog = false;
		},	
		actualizarItem(listaDet) {			
			this.loadingGrid = true;
			InvService.registrarListaPrecioDet(this.$store.state.auth.currentAppCtx, listaDet).then(data => {				
				
				if(listaDet.id_precio_lista_det == '') {
					listaDet.id_precio_lista_det = data.id_precio_lista_det;
					this.lista.detalle.push(listaDet);
				}
				
				this.loadingGrid = false;
			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.submitted = true;
				this.loadingGrid = false;
			})	
		},
		elegirProducto(Producto){
			this.productoDialog = false;	
			let listaDet =  {
				id_precio_lista_det : '',
				id_precio_lista : this.lista.id_precio_lista,
				id_producto_conversion: Producto.id_producto_conversion,
				codigo: Producto.codigo,
				producto: Producto.producto,
				utilizar: 'F',
				precio_unitario: Producto.precio_unitario,
				precio_pct: 0.0
			};
			this.actualizarItem(listaDet);
		},	
		actualizarLista() {
			if(!this.lista.hayCambios){
				return;
			}
			
			this.lista.activa = this.lista.activaBool?'S':'N';

			this.loading = true;
			InvService.registrarListaPrecio(this.$store.state.auth.currentAppCtx, this.lista).then(() => {								
				this.lista.hayCambios = false;
				this.loading = false;
			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.loading = false;
			})			
		},
		guardarRec(){
			this.nuevoDialog = false;	
			this.loading = true;
			InvService.registrarListaPrecio(this.$store.state.auth.currentAppCtx, this.lista).then(data => {				
				this.lista.id_precio_lista = data.id_precio_lista;
				this.listas.push(this.lista);
				this.lista = {};
				this.loading = false;
			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.submitted = true;
				this.nuevoDialog = false;
				this.loading = false;
			})			
		},
		removerItem(Item) {
			this.loadingGrid = true;
			InvService.eliminarListaPrecioDetalle(this.$store.state.auth.currentAppCtx, Item).then(() => {
				this.lista.detalle = this.lista.detalle.filter(val => val.id_precio_lista_det !== Item.id_precio_lista_det);
				this.loadingGrid = false;
			}
			).catch(
				data => {
					this.loadingGrid = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},			
		openNew() {
			this.lista = {
				id_precio_lista: "",
				id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
				precio_lista: "",
				automatica: "N",
				automaticaBool: false,				
				activa: "S",
				activaBool: true,
				pct_descuento: 0.0,
				detalle: []				
			};
			this.submitted = false;
			this.nuevoDialog = true;
		},
		hideDialog() {
			for(var i = 0; i < this.listas.length; i++) {
				if (this.listas[i].id_precio_lista == this.lista.id_precio_lista) {
					this.listas[i] = {...this.lista};
				}
			}
			this.listaDialog = false;
			this.submitted = false;
		},
		save() {			
			this.loadingGrid = true;
			InvService.registrarProducto(this.$store.state.auth.currentAppCtx, this.product).then(data => {				
				this.lista.id_producto_empresa = data.id_producto_empresa;
				this.lista.id_producto = data.id_producto;
				
				let index = this.products.findIndex((obj => obj.id_producto_empresa == this.product.id_producto_empresa));

				if(index == -1) {
					this.products.push(this.product);
				} else {
					this.products[index] = {...this.product};
				}

				this.product = {};
				
				this.submitted = true;
				this.productDialog = false;
				this.loadingGrid = false;

			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.submitted = true;
				this.productDialog = false;
				this.loadingGrid = false;
			})
		},
		edit(registro) {
			this.loading = true;

			this.lista = {...registro};
			InvService.getListaPrecios(this.$store.state.auth.currentAppCtx, this.lista.id_precio_lista).then((data) => {
					this.lista = data;
					this.lista.automaticaBool = (this.lista.automatica == 'S');
					this.lista.activaBool = (this.lista.activa == 'S');
					this.loading = false;
					this.listaDialog = true;
			}
			).catch(
				data => {
					this.listaDialog = false;
					this.lista = {};
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		confirmDelete(registro) {
			this.lista = registro;
			this.deleteDialog = true;
		},
		eliminar() {
			this.loading = true;
			InvService.eliminarListaPrecio(this.$store.state.auth.currentAppCtx, this.lista).then(() => {
					this.listas = this.listas.filter(val => val.id_precio_lista !== this.lista.id_precio_lista);
					this.deleteDialog = false;
					this.lista = {};
					this.loading = false;
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'La lista de precios ha sido eliminada', life: 3000})
			}
			).catch(
				data => {
					this.deleteProductDialog = false;
					this.product = {};
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
        onCellEditComplete(event) {
            if (!this.editingCellRows[event.index]) {
                return;
            }

            const editingCellValue = this.editingCellRows[event.index][event.field];

            switch (event.field) {
                case 'precio_unitario':
                    if (this.isPositiveNumeric(editingCellValue)){  
						this.lista.detalle[event.index]['precio_unitario'] = editingCellValue;
						this.actualizarItem(this.lista.detalle[event.index]);
					}
                    else
                        event.preventDefault();
                break;								
            }
        },		
        onCellEdit(newValue, props) {
            if (!this.editingCellRows[props.index]) {
                this.editingCellRows[props.index] = {...props.data};
            }

            this.editingCellRows[props.index][props.column.props.field] = newValue;
        },		
		buscarProducto()
		{
			// AppCtx, filtro, mostrarConversiones, tipoProducto, idLista, mostrarMateriaPrima
			InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroProducto, 'S', '', '', 'N').then(data => 
			{
				if(data.length == 1){
					data[0].mostrar = 'S';
					this.elegirProducto(data[0]);	
				} else {
					this.$refs.dlgBuscaProducto.mostrarResultadosBusqueda(this.filtroProducto, data);									
					this.productoDialog = true;
				}
				this.productoDialogLoading = false;
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
		},		
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteDialog = true;
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
